<template>
    <div>
        <div class="page-title">记账列表</div>

        <div>
            <a-table
                    class="stock-table"
                    :columns="table.columns"
                    :data-source="table.data"
                    :loading="table.loading"
                    :pagination="table.pagination"
                    :rowKey="record => record.id || record.key"
                    @change="tableChanged"
                    bordered
            >
                <div slot="time" slot-scope="time">
                    {{ formatTime(time) }}
                </div>

                <div slot="transaction_id" slot-scope="transactionId">

                    <a-tooltip>
                        <template slot="title">
                            <div style="width: 150px">{{ transactionId }}</div>
                        </template>
                        {{ transactionId.substring(16,transactionId.length) }}
                    </a-tooltip>

                </div>

                <div slot="payer" slot-scope="val,item">
                    <div v-if="item.sharing_info.order_info && item.sharing_info.order_info.member">
                        <div class="member-card">
                            <div><img :src="item.sharing_info.order_info.member.avatar" alt=""></div>
                            <span class="member-name">{{ item.sharing_info.order_info.member.mark_name || item.sharing_info.order_info.member.name }}</span>
                        </div>
                    </div>
                </div>

                <div slot="money" slot-scope="money">
                    ￥{{ money }}
                </div>

                <div slot="per" slot-scope="money">
                    {{ money }}%
                </div>

            </a-table>
        </div>
    </div>
</template>

<script>
    import api from "../../../repo/api";
    import moment from 'moment';

    export default {
        name: "GoodsSync",
        data() {
            return {
                routeMatch: '/ast/shop/bill',
                table: {
                    columns: [
                        {
                            title: 'ID',
                            dataIndex: 'id',
                            align: 'center',
                        },
                        {
                            title: '时间',
                            dataIndex: 'created_at',
                            align: 'center',
                            scopedSlots: {customRender: 'time'},
                        },
                        {
                            title: '订单',
                            dataIndex: 'sharing_info.order_info.transaction_id',
                            align: 'center',
                            scopedSlots: {customRender: 'transaction_id'},
                        },
                        {
                            title: '付款用户',
                            dataIndex: 'payer',
                            align: 'left',
                            scopedSlots: {customRender: 'payer'},
                        },
                        {
                            title: '支付金额',
                            dataIndex: 'sharing_info.order_info.amount',
                            align: 'center',
                            scopedSlots: {customRender: 'money'},
                        },
                        {
                            title: '分配总额',
                            dataIndex: 'sharing_info.order_info.sharing_amount',
                            align: 'center',
                            scopedSlots: {customRender: 'money'},
                        },
                        {
                            title: '分配类型',
                            dataIndex: 'sharing_info.memo',
                            align: 'center',
                        },
                        {
                            title: '比例',
                            dataIndex: 'sharing_info.share_scale',
                            align: 'center',
                            scopedSlots: {customRender: 'per'},
                        },
                        {
                            title: '分配金额',
                            dataIndex: 'sharing_info.share_amount',
                            align: 'center',
                            scopedSlots: {customRender: 'money'},
                        }
                    ],
                    loading: false,
                    data: [],
                    pagination: {
                        defaultCurrent: 1,
                        current: 1,
                        defaultPageSize: 10,
                        total: 1,
                        pageSize: 20
                    },
                },
                list: [],
                query: {
                    id: 0,
                    page: 1,
                    order_by: 'time',
                    order_type: 'desc',
                },
            }
        },
        mounted() {
            this.getFirstList()
        },
        methods: {
            getFirstList() {
                this.query.id = this.$route.params.id
                this.query.page = 1
                this.getList()
            },
            getList() {

                this.table.loading = true

                api.get('/ast-cashier/incomes', this.query, (data) => {

                    this.table.data = data.data.data
                    this.table.pagination.current = data.data.current_page
                    this.table.pagination.total = data.data.total
                    this.table.pagination.pageSize = data.data.per_page

                    this.table.loading = false

                })
            },
            formatTime(time) {
                return moment(time).format('yyyy-MM-DD HH:mm')
            },

            tableChanged(pagination, filter, sorter) {
                this.query.order_by = sorter.field
                this.query.order_type = sorter.order === 'descend' ? 'desc' : 'asc'
                this.query.page = pagination.current

                this.getList()
            }
        }
    }
</script>

<style lang="less" scoped>
    .mt16 {
        margin-top: 16px;
    }

    .member-card {
        display: flex;
        align-items: center;
        align-content: center;
        flex-direction: row;

        img {
            width: 24px;
            height: 24px;
            margin-right: 4px;
        }

        .member-name {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }
    }

    .cj {
        color: #FBB03B;
    }

    .yc-only .member-name {
        color: #FBB03B;
    }

    .remark {
        width: 100px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
    }

    .member-id {
        height: 20px;
        text-align: center;
        background-color: #ddd;
        font-size: 12px;
        margin-top: 5px;
        color: white;
        position: relative;
        padding: 2px 2px 2px 10px;

        &:before {
            position: absolute;
            left: 5px;
            content: ' ID ';
        }
    }

    .bill-remark {
        width: 100%;
        height: 120px;
    }

    .remark-title {
        margin-bottom: 16px;
    }

    .remark-item {
        margin-bottom: 24px;
    }
</style>